import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Paragraph from "../../../components/Paragraph";
import Browser from "../../../components/BrowserDemo";
export const meta = {
  published: false,
  publishedAt: "2019-01-30",
  title: "57% of Developers Misunderstand the Cascade in CSS",
  summary: ""
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`57% of frontend developers misunderstand the "Cascade" in Cascading Style Sheets (CSS). Do you?`}</p>
    <p>{`Given these classes, which color would the divs be?`}</p>
    <pre {...{
      "className": "language-html"
    }}><code parentName="pre" {...{
        "className": "language-html"
      }}><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`style`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span><span parentName="code" {...{
          "className": "token style"
        }}><span parentName="span" {...{
            "className": "token language-css"
          }}>{`
  `}<span parentName="span" {...{
              "className": "token selector"
            }}><span parentName="span" {...{
                "className": "token class"
              }}>{`.red`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` red`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

  `}<span parentName="span" {...{
              "className": "token selector"
            }}><span parentName="span" {...{
                "className": "token class"
              }}>{`.blue`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="span" {...{
              "className": "token property"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` blue`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}</span></span><span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`</`}</span>{`style`}</span><span parentName="span" {...{
            "className": "token punctuation"
          }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`red blue`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "token tag"
        }}><span parentName="span" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
            "className": "token attr-name"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "token attr-value"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span>{`blue red`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`"`}</span></span>{` `}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`/>`}</span></span>{`
`}</code></pre>
    <p>{`Possible answers:`}</p>
    <ol>
      <li parentName="ol">{`First red, second blue`}</li>
      <li parentName="ol">{`First blue, second red`}</li>
      <li parentName="ol">{`Both blue`}</li>
      <li parentName="ol">{`Both red`}</li>
    </ol>
    <p>{`What do you think?`}</p>
    <hr></hr>
    <details>
  <Paragraph style={{
        display: 'inline-block'
      }} as="summary" mdxType="Paragraph">Click to see the correct answer</Paragraph>
      <Paragraph mdxType="Paragraph">Both will be blue! Here is a live demo:</Paragraph>
      <Browser html={`
    <style>
      .box {
        width: 50px;
        height: 50px;
        margin: 8px;
        color: white;
        font-family: sans-serif;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .wrapper {
        display: flex;
        flex-direction: row;
      }
      .red {
        background: red;
      }
      .blue {
        background: blue;
      }
    </style>
    <div class="wrapper">
      <div class="box red blue">1</div>
      <div class="box blue red">2</div>
    </div>
    `} mdxType="Browser" />
    </details>
    <p>{`If that surprises you, do not worry. `}<a parentName="p" {...{
        "href": "https://twitter.com/mxstbr/status/1038416725182697472"
      }}>{`Only 43% out of 13,000 developers answered correctly`}</a>{` in an informal Twitter poll.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      